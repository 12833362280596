import '../styles/Profile.css'
import '../styles/styleMobile.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboard, faPen, faCheck, faXmark, faSpinner, faCircleExclamation, faCircleQuestion, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Moment from 'moment-timezone';
import { useSelector } from 'react-redux'
import axios from 'axios';
import configV from '../config/config'

const Profile = () => {
    const [userProduct, setUserProduct] = useState([]);
    const [openEditIp, setOpenEditIp] = useState({});
    const [newOneIP, setNewOneIP] = useState('');
    const [newAllIP, setNewAllIP] = useState('');
    const [indexScript, setIndexScript] = useState(0);
    const [modelsNewAllIPScript, setModelsNewAllIPScript] = useState(false)
    const [modelsConfrimAllIPScript, setModelsConfrimAllIPScript] = useState(false)
    const [modelsNewOneIPScript, setModelsNewOneIPScript] = useState(false)
    const [loading, setLoading] = useState(false);
    const [found, setFound] = useState(false)
    const data = useSelector((state) => state);

    let isload = false
    useEffect(() => {
        const check = document.querySelector('select');
        if (!check) {
            onGetProduct();
        }
    }, []);


    const onGetProduct = async () => {
        setLoading(true);
        if (isload) {
            return
        }
        isload = true
        const API = `${configV.URL}/getAllScript`
        await axios.post(API, {
            "clientID": data.clientID
        })
            .then((response) => {
                const user = response.data;
                const script = JSON.parse(user.data)
                const setScript = JSON.parse(script.scripts)

                const listresponse = setScript.map(
                    (item, i) => {
                        const setdata = Moment(item.date, "DD-MM-yyyyTHH:mm:ss.SSSZ").tz("Asia/Bangkok").format("DD-MM-YYYY");
                        return {
                            ...item,
                            date: setdata
                        };
                    }
                );
                setLoading(false);
                setUserProduct(listresponse);


            }).catch((error) => {
                setFound(true)
                setLoading(false);
            });

    }

    const changeNewOneIP = (e, index) => {
        const newIP = e.target.value;
        const filteredHostIp = newIP.replace(/[^\d.]/g, '');
        setNewOneIP({
            [index]: filteredHostIp,
        });
    };
    const changeAllIP = (e) => {
        const newHostIp = e.target.value;
        const filteredHostIp = newHostIp.replace(/[^\d.]/g, '');
        setNewAllIP(filteredHostIp);
    }

    const editAllIP = () => {
        setModelsNewAllIPScript(true)
    }

    const onChangeAllIP = () => {
        if (!newAllIP) {
            toast.warn("กรุณากรอก IP")
            return
        } else {
            setModelsConfrimAllIPScript(true)
            setModelsNewAllIPScript(false)
        }
    }

    const onCancelAllIP = () => {
        setModelsNewAllIPScript(false)
        setNewAllIP('')
    }

    const changeModelsConfrimAllIP = async () => {
        let listScripts = []
        setLoading(true);

        userProduct.map((person) => {
            listScripts.push({
                "script": person.script,
                "ip": newAllIP
            })
        });
        const API = `${configV.URL}/changeIP`

        await axios.post(API, {
            "clientID": data.clientID,
            "access_token": data.accessToken,
            "listScripts": listScripts
        })
            .then((response) => {
                const user = response.data;
                const script = JSON.parse(user.data)
                const listScripts = script.listScripts

                const listresponse = userProduct.map((person) => {
                    const updatedPerson = listScripts.find((element) => {
                        return person.script === element.script && person.ip === element.oldip;
                    });
                    if (updatedPerson) {
                        return { ...updatedPerson, ip: updatedPerson.ip, date: person.date };
                    }

                    return person;
                });

                setUserProduct(listresponse);
                setLoading(false);
                toast.success("เปลี่ยน IP สำเร็จ")
                setModelsConfrimAllIPScript(false)
                setNewAllIP('')

            }).catch((error) => {
                setLoading(false);
            });
    }

    const cancelModelsConfrimAllIP = () => {
        setModelsConfrimAllIPScript(false)
        setNewAllIP('')
    }

    const editOneIP = (index) => {
        setOpenEditIp((prevOpenEditIp) => ({
            ...prevOpenEditIp,
            [index]: true,
        }));
    }

    const cancelEditOneIP = (index) => {
        setOpenEditIp((prevOpenEditIp) => ({
            ...prevOpenEditIp,
            [index]: false,
        }));
        setNewOneIP('')
    }

    const confirmEditOneIP = (index) => {
        setIndexScript(index)
        if (!newOneIP) {
            toast.warn("กรุณากรอก IP")
            return
        } else {
            setModelsNewOneIPScript(true)
        }
    }

    const changeIPOneIPScript = () => {
        const updatedPeople = userProduct.map((person, i) => {
            if (i === indexScript) {
                return { ...person, ip: newOneIP[indexScript] };
            }
            return person;
        });

        let listScripts = [
            {
                "script": updatedPeople[indexScript].script,
                "ip": updatedPeople[indexScript].ip
            }
        ]

        setNewOneIP('')
        setModelsNewOneIPScript(false)
        setOpenEditIp(false)
        setLoading(true);

        const API = `${configV.URL}/changeIP`

        axios.post(API, {
            "clientID": data.clientID,
            "listScripts": listScripts
        })
            .then((response) => {
                const user = response.data;
                const script = JSON.parse(user.data)
                const Name = script.listScripts[0].script
                const NewIP = script.listScripts[0].ip
                const oldip = script.listScripts[0].oldip

                const listresponse = userProduct.map((person) => {
                    if (person.script === Name && person.ip === oldip) {
                        return { ...person, ip: NewIP };
                    }
                    return person;
                });
                setUserProduct(listresponse);
                setLoading(false);
                toast.success("เปลี่ยน IP สำเร็จ")

            }).catch((error) => {
                setLoading(false);
            });

    }

    const cancelModelsOneIPScript = () => {
        setNewOneIP('')
        setModelsNewOneIPScript(false)
        setOpenEditIp(false)
    }


    return (
        <div className="from-profile">
            {found ? (
                <div className="text-center cursor-default">
                    <img className='img-found mr-[auto] ml-[auto] w-[30%]'
                        src="https://media.discordapp.net/attachments/796649426214977547/1133717293717798932/Lovepik_com-832483587-25D_style_404_error_gradient_art_word_can_be_commercial_elements.png?width=671&height=671" alt="" />
                    <h1 className="mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl">Page not found</h1>
                    <p className="mt-6 text-base leading-7 text-white">Sorry, we couldn’t find the page you’re looking for.</p>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <a href="/" className="text-sm text-yellow-500 font-bold flex gap-[10px] items-center hover:bg-orange-300 hover:text-white delay-75 p-[10px] rounded-[10px] backdrop-blur-sm bg-white/20">
                            Go back home
                            <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                        </a>
                    </div>
                </div>
            ) : (
                <>
                    <div className='layout-profile cursor-default'>
                        {loading && (
                            <div className='fixed text-white gap-[1vw] top-0 left-0 w-[100%] h-[100%] z-[1000] flex justify-center items-center bg-black bg-opacity-50'>
                                <FontAwesomeIcon icon={faSpinner} className='icon-spinner animate-spin '></FontAwesomeIcon>
                                load...
                            </div>
                        )}
                        <div className='host-ip text-white flex gap-[10px] items-center justify-end'>
                            <FontAwesomeIcon icon={faClipboard}></FontAwesomeIcon>
                            <p className='pt-[5px]'>IP Server : </p>
                            <button type="" className='p-[5px] text-white text-[13px] font-bold bg-gradient-to-r from-amber-500 to-amber-700 rounded-[5px] hover:scale-[.98] transition ease-in-out'
                                onClick={editAllIP}>เปลี่ยนทั้งหมด</button>
                        </div>
                        <div className='layout-table'>
                            <div className='grid-header-table flex justify-center items-center text-white bg-white backdrop-blur-sm bg-white/20 p-[10px] rounded-[10px] mt-[20px]'>
                                <div className="basis-1/4"><p>วันที่ซื้อ</p></div>
                                <div className="basis-1/4 text-center"><p>ชื่อ</p></div>
                                <div className="text-ip basis-1/3 text-right"><p>IP Server</p></div>
                            </div>
                            <div className='grid-body-table'>
                                <div className='list-body-table'>
                                    {userProduct.map((product, index) => (
                                        <div key={index} className='card-table flex justify-between items-center gap-x-6 py-[10px] px-[50px] mt-1 bg-white backdrop-blur-sm bg-white/10 rounded-[10px]'>
                                            <div className=""><p className='text-date'>{product.date}</p></div>
                                            <div className=""><p className='text-script'>{product.script}</p></div>
                                            {openEditIp[index] ? (
                                                <div className=" flex justify-end gap-[10px] items-center">
                                                    <form className="">
                                                        <input
                                                            type="text"
                                                            name="IP"
                                                            value={newOneIP[index] || ''}
                                                            onChange={(e) => changeNewOneIP(e, index)}
                                                            placeholder='New IP'
                                                            className="input-one-ip rounded-[5px] text-black focus:outline-none w-[120px] px-[5px] text-[13px]"
                                                        />
                                                    </form>
                                                    <FontAwesomeIcon
                                                        onClick={() => confirmEditOneIP(index)}
                                                        icon={faCheck}
                                                        className="cursor-pointer text-[13px] text-white"
                                                    ></FontAwesomeIcon>
                                                    <FontAwesomeIcon
                                                        onClick={() => cancelEditOneIP(index)}
                                                        icon={faXmark}
                                                        className="cursor-pointer text-[15px] text-white"
                                                    ></FontAwesomeIcon>
                                                </div>
                                            ) : (
                                                <div className=" flex justify-end gap-[10px] items-center">
                                                    <p className="ip-scrip text-sm leading-6 text-white">{product.ip}</p>
                                                    <FontAwesomeIcon
                                                        icon={faPen}
                                                        className="icon-pen cursor-pointer text-[13px] text-white"
                                                        onClick={() => editOneIP(index)}
                                                    ></FontAwesomeIcon>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    {modelsNewAllIPScript && (
                        <div className='models absolute top-0 left-0 z-[999] bg-white backdrop-blur-sm bg-white/5 w-[100%] h-[100%] transition delay-150 duration-300 ease-in-out'>
                            <div className='card-models absolute grid place-items-center top-[40%] left-[40%] p-[30px] px-[80px] rounded-[10px] ease-in-out'>
                                <FontAwesomeIcon icon={faCircleQuestion} className='icon-warm text-[50px] text-yellow-500 animate-bounce'></FontAwesomeIcon>
                                <p className='font-bold text-white pt-[10px] text-center'>IP Server</p>
                                <input
                                    type="text"
                                    name="ip"
                                    value={newAllIP}
                                    onChange={changeAllIP}
                                    className='border-2 border-amber-600 bg-black backdrop-blur-sm bg-white/20 text-white font-bold rounded-[5px] focus:outline-none pl-[5px] text-[13px] p-[2px] pt-[5px] mt-[10px]' />
                                <div className='flex mt-[15px] gap-[20px]'>
                                    <button type="" className='btn-models p-[5px] text-white bg-gradient-to-r from-green-500 to-green-700 px-[10px] rounded-[10px] hover:scale-[.9] transition ease-in-out'
                                        onClick={onChangeAllIP}>เปลี่ยนทั้งหมด</button>
                                    <button type="" className='btn-models p-[5px] text-white bg-gradient-to-r from-red-500 to-red-900 px-[10px] rounded-[10px] hover:scale-[.9] transition ease-in-out'
                                        onClick={onCancelAllIP}>ยกเลิก</button>
                                </div>
                            </div>
                        </div>
                    )}
                    {modelsConfrimAllIPScript && (
                        <div className='models absolute top-0 left-0 z-[999] bg-white backdrop-blur-sm bg-white/5 w-[100%] h-[100%] transition delay-150 duration-300 ease-in-out'>
                            <div className='card-models absolute grid place-items-center top-[40%] left-[40%] p-[30px] px-[50px] rounded-[10px] ease-in-out'>
                                <FontAwesomeIcon icon={faCircleExclamation} className='icon-warm text-[50px] text-amber-500 animate-bounce'></FontAwesomeIcon>
                                <p className='font-bold text-white pt-[10px] text-center'>ยืนยันการเปลี่ยน IP Server หรือไม่?</p>
                                <div className='flex mt-[10px] gap-[10px]'>
                                    <button type="" className='btn-models p-[5px] px-[10px] text-white bg-gradient-to-r from-green-500 to-green-700 rounded-[5px] hover:scale-[.9] transition ease-in-out'
                                        onClick={changeModelsConfrimAllIP}
                                    >ตกลง</button>
                                    <button type="" className='btn-models p-[5px] px-[10px] text-white bg-gradient-to-r from-red-500 to-red-900 rounded-[5px] hover:scale-[.9] transition ease-in-out'
                                        onClick={cancelModelsConfrimAllIP}
                                    >ยกเลิก</button>
                                </div>
                            </div>
                        </div>
                    )}
                    {modelsNewOneIPScript && (
                        <div className='models absolute top-0 left-0 z-[999] bg-white backdrop-blur-sm bg-white/5 w-[100%] h-[100%] transition delay-150 duration-300 ease-in-out'>
                            <div className='card-models absolute grid place-items-center top-[40%] left-[40%] p-[30px] px-[50px] rounded-[10px] ease-in-out'>
                                <FontAwesomeIcon icon={faCircleExclamation} className='icon-warm text-[50px] text-amber-500 animate-bounce'></FontAwesomeIcon>
                                <p className='font-bold text-white pt-[10px] text-center'>ยืนยันการเปลี่ยน IP Script หรือไม่?</p>
                                <div className='flex mt-[10px] gap-[10px]'>
                                    <button type="" className='btn-models p-[5px] px-[10px] text-white bg-gradient-to-r from-green-500 to-green-700 rounded-[5px] hover:scale-[.9] transition ease-in-out'
                                        onClick={changeIPOneIPScript}
                                    >ตกลง</button>
                                    <button type="" className='btn-models p-[5px] px-[10px] text-white bg-gradient-to-r from-red-500 to-red-900 rounded-[5px] hover:scale-[.9] transition ease-in-out'
                                        onClick={cancelModelsOneIPScript}
                                    >ยกเลิก</button>
                                </div>
                            </div>
                        </div>
                    )}
                    <ToastContainer theme="dark" />
                </>
            )}


        </div>
    )
}


export default Profile