import { NavLink } from 'react-router-dom'
import './Navbar.css'
import '../styles/styleMobile.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoxOpen, faRightToBracket, faXmark, faBars, faHouse, faRightFromBracket, faSpinner, faIdBadge, faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { faDiscord } from '@fortawesome/free-brands-svg-icons'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { login, logout } from '../storeSlice/contextSlice/Context';
import axios from 'axios';
import configV from '../config/config'

const Navbar = () => {
    const handleClick = () => setClick(!click)
    const closeMobileMenu = () => setClick(false)
    const dispatch = useDispatch()
    const [click, setClick] = useState(false)
    const [userlogin, setLogin] = useState(true)
    const [showLogin, setShow] = useState(false)
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState(null);
    const logo = "https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/logo-funny.png?alt=media&token=9c0030b2-1bff-462c-8ac3-8e0c9e263d3f"
    const data = useSelector((state) => state);

    const handleLogin = () => {
        window.location.href = 'https://discord.com/api/oauth2/authorize?client_id=1148326422117621760&redirect_uri=https%3A%2F%2Ffunny-production.com&response_type=code&scope=identify%20connections';
        setClick(false)
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');

        if (code) {
            setLoading(true)
            const config = {
                headers: {
                    'ngrok-skip-browser-warning': 'true',
                },
            };

            axios.get(`${configV.URL}/api/callback?code=${code}`,config)
                .then((response) => {
                    const user = JSON.parse(response.data.data);
                    dispatch(login(user))
                    setLoading(false)
                })
                .catch((error) => {
                    setLoading(false)
                });
        }

        if (!data.clientID) {
            return
        }

        if (!data.accessToken) {
            dispatch(logout())
        }

        if (!data.avatarUrl) {
            return
        }

        if (data.clientID !== 0) {
            setShow(true)
            setLogin(false)

            const cleanedClientID = data.clientID.replace(/"/g, '');
            const cleanedAvatarUrl = data.avatarUrl.replace(/"/g, '');
            setImage(`https://cdn.discordapp.com/avatars/${cleanedClientID}/${cleanedAvatarUrl}.png`)
        }

    }, [data.clientID]);

    const onClickLogo = () => {
        window.location = '/'
    }

    const clickUser = () => {
        setClick(false)
        window.location = '/profile'
    }

    const checkLogout = () => {
        window.location = '/'
        setShow(false)
        setLogin(true)
        dispatch(logout())
        setClick(false)
    }

    return (
        <div className='from-navbar'>
            {loading && (
                <div className='fixed text-white gap-[1vw] top-0 left-0 w-[100%] h-[100%] z-[1000] flex justify-center items-center bg-black bg-opacity-50'>
                    <FontAwesomeIcon icon={faSpinner} className='icon-spinner animate-spin '></FontAwesomeIcon>
                    load...
                </div>
            )}
            <div onClick={onClickLogo} className='flex items-center gap-3 cursor-pointer'>
                <img className='w-[50px] h-[50px] object-cover cursor-pointer' src={logo} />
                <p className='text-name-logo text-[1.2vw] font-bold text-white cursor-pointer'>FUNNY PRODUCTION</p>
            </div>
            <div className={click ? 'section active' : 'section'}>
                <NavLink to='/' onClick={closeMobileMenu} className={({ isActive }) =>
                    isActive ? 'navbar-home active-navbar-home' : 'navbar-home'
                }>
                    <FontAwesomeIcon icon={faHouse} className='icon-navbar'></FontAwesomeIcon>
                    <p>หน้าหลัก</p>
                </NavLink>
                <NavLink to='/peoducts' onClick={closeMobileMenu} className={({ isActive }) =>
                    isActive ? 'navbar-home active-navbar-home' : 'navbar-home'
                }>
                    <FontAwesomeIcon icon={faBoxOpen} className='icon-navbar'></FontAwesomeIcon>
                    <p>สินค้า</p>
                </NavLink>
                {userlogin ? (
                    <NavLink to='https://discord.com/api/oauth2/authorize?client_id=1148326422117621760&redirect_uri=https%3A%2F%2Ffunny-production-2fbe8.web.app&response_type=code&scope=identify%20email%20connections'
                        onClick={closeMobileMenu} className={({ isActive }) =>
                            isActive ? 'navbar-home active-navbar-home' : 'navbar-home'
                        }>
                        <FontAwesomeIcon icon={faIdBadge} className='icon-navbar'></FontAwesomeIcon>
                        <p className='fonts-text'>LICENSE</p>
                    </NavLink>
                ) : (
                    <NavLink to='/profile' onClick={closeMobileMenu} className={({ isActive }) =>
                        isActive ? 'navbar-home active-navbar-home' : 'navbar-home'
                    }>
                        <FontAwesomeIcon icon={faIdBadge} className='icon-navbar'></FontAwesomeIcon>
                        <p className='fonts-text'>LICENSE</p>
                    </NavLink>
                )}

                <NavLink to='https://discord.gg/XcUEZwDAk2' target="_blank" rel="noreferrer" onClick={closeMobileMenu} className={({ isActive }) =>
                    isActive ? 'navbar-home active-navbar-home' : 'navbar-home'
                }>
                    <FontAwesomeIcon icon={faDiscord} className='icon-navbar'></FontAwesomeIcon>
                    <p className='fonts-text'>DISCORD</p>
                </NavLink>

                <NavLink to='https://www.youtube.com/channel/UCQBik3ISSwgkWqwDmD0xHlw' target="_blank" rel="noreferrer" className={({ isActive }) =>
                    isActive ? 'navbar-home active-navbar-home' : 'navbar-home'
                }>
                    <FontAwesomeIcon icon={faCircleInfo} className='icon-navbar'></FontAwesomeIcon>
                    <p>ช่วยเหลือ</p>
                </NavLink>
                <p className='bookmark'>|</p>
                {userlogin ? (
                    <NavLink onClick={handleLogin} className={({ isActive }) => isActive ? 'navbar-login' : 'navbar-login'}>
                        <FontAwesomeIcon icon={faRightToBracket} className='icon-login'></FontAwesomeIcon>
                        <span>เข้าสู่ระบบ</span>
                    </NavLink>
                ) : null}

                {showLogin ? (
                    <>
                        <p className='text-name max-w-[30%] overflow-hidden whitespace-nowrap text-ellipsis text-[15px] pt-[5px] font-bold'>{data.name}</p>
                        <div className="skill cursor-pointer" onClick={clickUser}>
                            <div className="outer">
                                <img className='image-user object-cover rounded-[50vw]' src={image} alt="" />
                            </div>
                            <svg id="skillSVG" xmlns="http://www.w3.org/2000/svg" version="1.1" width="5.4vw" height="5.4vw">
                                <defs>
                                    <linearGradient id="GradientColor">
                                        <stop offset="0%" stopColor="#fc9c0d" />
                                        <stop offset="100%" stopColor="#F7CE68" />
                                    </linearGradient>
                                </defs>
                                <circle id="skillCircle" cx="55.5%" cy="47%" r="40%" strokeLinecap="round" fill="none" strokeWidth="6%"
                                    stroke="url(#GradientColor)" strokeDasharray="248%" strokeDashoffset="60%" />
                            </svg>
                        </div>
                        <FontAwesomeIcon
                            icon={faRightFromBracket}
                            onClick={() => checkLogout()}
                            className='icon-logout transition ease-in-out delay-75 hover:scale-[1.1] duration-300 cursor-pointer'></FontAwesomeIcon>
                    </>
                ) : null}
            </div>
            <div className='menu' onClick={handleClick}>
                {click ? (
                    <FontAwesomeIcon icon={faXmark} className='icon-menu'></FontAwesomeIcon>
                ) : (
                    <FontAwesomeIcon icon={faBars} className='icon-menu'></FontAwesomeIcon>
                )}
            </div>
        </div>
    )
}

export default Navbar