import '../styles/Home.css'
import '../styles/styleMobile.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord, faYoutube, faTiktok, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { NavLink } from 'react-router-dom'
import { useSpring, animated } from '@react-spring/web'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react'

const Home = () => {

    useEffect(() => {
        AOS.init({
            duration: 1000,
            offset: 100,
        });
    }, []);

    return (
        <div className='from-home'>
            <div className='from-header-home'>
                <div className='background'>
                    <img className='img-bg' src="https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/bg-home-funny.png?alt=media&token=3c1cf7bb-0e40-4609-bb06-5ba6302c50d6" alt="" />
                </div>
                <div className='text-header-home'>
                    <div className='layout-text-header-home'>
                        <div className='text-header flex gap-[5%] text-center justify-center text-[5vw] font-bold cursor-default'>
                            <p className='text-amber-500'>FUNNY</p>
                            <p>PRODUCTION</p>
                        </div>
                        <p className='text-header-center text-[2.5vw] cursor-default'>จินตนาการของคุณ คืองานของเรา</p>
                        <NavLink to='/peoducts' >
                            <button className=' bg-yellow-500 text-[25px] font-bold p-[.8%] rounded-[10px] pl-[3%] pr-[3%]'>SHOP NOW!</button>
                        </NavLink>
                    </div>
                    <div className='section-header-home cursor-default'>
                        <p className='text-[1.7vw] font-bold'>ซื้อสินค้า</p>
                        <span>
                            <p className='text-[1.7vw] font-bold'>เปลี่ยน</p>
                            <p className='text-[1.5vw]'>IP</p>
                        </span>
                        <p className='text-[1.7vw] font-bold'>อัพเดตระบบ</p>
                        <p className='text-[1.7vw] font-bold'>จบครบในที่เดียว</p>
                    </div>
                </div>
            </div>
            <div className='from-body-home cursor-default'>
                <div className='text-about-production' data-aos="fade-right" data-aos-offset="50" data-aos-easing="ease-in-sine">
                    <p className='text-[1.7vw] h-[30px]'>ทำไมต้องเป็นเรา ?</p>
                    <span>
                        <p className='text-amber-400'>FUNNY</p>
                        <p>PRODUCTION</p>
                    </span>
                    <p className='text-[1.15vw] mt-[1vw]'>เพราะเราคือบริษัท โปรดักส์ชั่นเฮาส์ ที่มีประสาบการณ์และทำงานในวงการ Fivem มาแล้วมากกว่า 4 ปี และมีฐานลูกค้ามากกว่า 1000 เซิร์ฟเวอร์ทั้งในไทยและต่างประเทศ</p>
                    <p className='text-[1.15vw] mt-[1vw]'>FUNNY Production เราให้บริการด้านการออกแบบที่ดีไซน์ตามจินตนาการของลูกค้า</p>
                    <p className='text-[1.15vw] mt-[1vw]'>ทั้งนี้ FUNNY Production เคยร่วมงานกับองค์กรต่างๆ มาแล้วทั้งภาครัฐและเอกชน ในส่วนงานสื่อมัลติมิเดี่ย ทั้งสื่อสิ่งพิมพ์ สื่อภาพนิ่ง และสื่อเตลื่อรไหวด้วยประสนการณ์การทำงานด้านโปรดักส์ชั่นโดยตรง กว่า 5 ปีเด็ม</p>
                </div>
                <img className='img-about-production ' data-aos="fade-left" data-aos-offset="50" data-aos-easing="ease-in-sine" src="https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/about-funny.png?alt=media&token=cbc0ca01-120e-477e-aa63-ace069da7404" alt="" />
            </div>
            <div className='from-footer-home cursor-default'>
                <div className='footer-header'>
                    <p className='text-amber-400 font-bold text-[1.3vw]' data-aos="fade-down">FUNNY PRODUCTION</p>
                    <p className='text-[3vw] font-bold tracking-wide' data-aos="fade-down">เราทำอะไรบ้าง ?</p>
                    <div className='layout-footer-header'>
                        <span data-aos="fade-right" data-aos-offset="50" data-aos-easing="ease-in-sine">
                            <img className='icon-section' src="https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/icon-server.png?alt=media&token=ad8a453f-400a-4200-bd16-3bb6d5d341e8" alt="" />
                            <label>
                                <p>เซิร์ฟเวอร์ พร้อมเปิด</p>
                                <p>ให้บริการเซิร์ฟเวอร์ FIVEM พร้อมเปิด พร้อมติดตั้ง ดูแลตลอดการใช้งาน สคริปต์ลื่นๆ พร้อมออกแบบ UI ในราคาย่อมเยาว์</p>
                            </label>
                        </span>
                        <span data-aos="fade-left" data-aos-offset="50" data-aos-easing="ease-in-sine">
                            <img className='icon-section' src="https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/icon-custom.png?alt=media&token=2eb071f7-3b7a-40d9-b094-1719abe54d80" alt="" />
                            <label>
                                <p>ออกแบบ custom UI</p>
                                <p>บริการออกแบบและตกแต่งหน้าเซิร์ฟเวอร์ด้วยการ custom ui ต่างๆ ภายในเซิร์ฟเวอร์ให้สวยโดดเด่นไม่ซ้ำใคร ในแบบสไตล์ของคุณเอง</p>
                            </label>
                        </span>
                        <span data-aos="fade-right" data-aos-offset="50" data-aos-easing="ease-in-sine">
                            <img className='icon-section' src="https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/icon-animation.png?alt=media&token=1d2b95a5-c106-4a63-bea3-43fd4c93f1d0" alt="" />
                            <label>
                                <p>ออกแบบ Animation</p>
                                <p>ออกแบบและสร้าง Amination ต่างๆ เช่น GIF LOGO LOADING SCREEN ช่วยให้โลโก้มีความสวยงาม ขยับเคลื่อนไหวในของคุณเอง</p>
                            </label>
                        </span>
                        <span data-aos="fade-left" data-aos-offset="50" data-aos-easing="ease-in-sine">
                            <img className='icon-section' src="https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/icon-graphics.png?alt=media&token=4f789bd0-8a5e-4302-8fce-c226f521e7da" alt="" />
                            <label>
                                <p>ออกแบบ Graphics</p>
                                <p>ออกแบบงานกราฟิก เช่น Logo , Poster ให้ดูมีสไตล์ตามใจ ตามจินตนาการของคุณ พร้อมยกระดับความคิดของคุณสู่ชิ้นงานที่เป็นจริง</p>
                            </label>
                        </span>
                    </div>
                </div>
                <div className='footer-footer'>
                    <img className='img-footer-home' src="https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/logo-funny.png?alt=media&token=9c0030b2-1bff-462c-8ac3-8e0c9e263d3f" alt="" />
                    <p className='text-[1.7vw] font-bold'>บริษัท ฟันนี่ โปรดักส์ชั่น จำกัด</p>
                    <div className='flex gap-[30px]'>
                        <NavLink to='https://www.tiktok.com/@funny_production2020' target="_blank" rel="noreferrer" >
                            <FontAwesomeIcon icon={faTiktok} className='icon-contect cursor-pointer'></FontAwesomeIcon>
                        </NavLink>
                        <NavLink to='https://www.facebook.com/Budokgraphic' target="_blank" rel="noreferrer" >
                            <FontAwesomeIcon icon={faFacebookF} className='icon-contect cursor-pointer'></FontAwesomeIcon>
                        </NavLink>
                        <NavLink to='https://www.youtube.com/channel/UCQBik3ISSwgkWqwDmD0xHlw' target="_blank" rel="noreferrer" >
                            <FontAwesomeIcon icon={faYoutube} className='icon-contect cursor-pointer'></FontAwesomeIcon>
                        </NavLink>
                        <NavLink to='https://discord.gg/XcUEZwDAk2' target="_blank" rel="noreferrer" >
                            <FontAwesomeIcon icon={faDiscord} className='icon-contect cursor-pointer'></FontAwesomeIcon>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home