import '../styles/Products.css'
import '../styles/styleMobile.scss'
import { useDispatch } from 'react-redux';
import { viewProduct } from '../storeSlice/contextSlice/Context';
import React, { useEffect, useState } from 'react';
import { db } from '../config/FirebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const Products = () => {

    const dispatch = useDispatch()
    const [scriptAll, setScriptAll] = useState([])
    const [scriptAdvice, setScriptAdvice] = useState([])
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        load()
        console.log('pppp');
    }, []);

    const load = async () => {
        setLoading(true)
        console.log('ooo');
        await getDocs(collection(db, 'สินค้าทั้งหมด'))
            .then((response) => {
                setLoading(false)
                const newData = response.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data()
                }))
                setScriptAll(newData)
            })
            .catch(err => {
                setLoading(false)
            })

        await getDocs(collection(db, 'สินค้าแนะนำ'))
            .then((response) => {
                setLoading(false)
                const newData = response.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data()
                }))
                setScriptAdvice(newData)
            })
            .catch(err => setLoading(false))
    }


    const onProduct = (item) => {
        const action = {
            item
        };
        dispatch(viewProduct(action));
        window.location = '/viewproduct'
    }

    return (
        <div className="from-products">
            {loading && (
                <div className='fixed text-white gap-[1vw] top-0 left-0 w-[100%] h-[100%] z-[1000] flex justify-center items-center bg-black bg-opacity-50'>
                    <FontAwesomeIcon icon={faSpinner} className='icon-spinner animate-spin '></FontAwesomeIcon>
                    load...
                </div>
            )}
            <div className='layout-header-products'>
                <div className='background-products'>
                    <img className='img-bg-products' src="https://firebasestorage.googleapis.com/v0/b/mrb-shop-9a085.appspot.com/o/imagebgmane-01.png?alt=media&token=bbcda2fd-2f73-4893-9936-c25a34bcd15c" alt="" />
                </div>
                <div className='text-header-products cursor-default'>
                    <span>
                        <div className='text-section-products'>
                            <p className='text-amber-500 text-[3.5vw]'>SCRIPT</p>
                            <p className='text-amber-500 text-[2.5vw]'>/</p>
                            <p className='text-amber-500 text-[3.5vw]'>UI PRODUCT</p>
                        </div>
                        <div className='text-details-section'>
                            <p className='text-[1.5vw] font-bold tracking-[.10em]'>สินค้าสคริปต์และ</p>
                            <p className='text-[1.4vw] font-bold tracking-[.10em]'>UI</p>
                            <p className='text-[1.5vw] font-bold tracking-[.10em]'>ของเรา</p>
                        </div>
                    </span>
                </div>
            </div>
            <div className='layout-body-products'>
                <div className='layout-recommended-products'>
                    <p className='text-sction-recommended text-[1.1vw] font-bold tracking-[.09em] cursor-default'>สินค้าแนะนำ</p>
                    <div className='grid-list-recommended'>
                        {scriptAdvice.map((advice, index) => (
                            <div className='scroll m-[0] w-[100%]' key={index}>
                                <div onClick={() => onProduct(advice)} className='card-recommended-products hover:scale-[1.02]'>
                                    {advice.img.map((line, index) => (
                                        <React.Fragment key={index}>
                                            {index === 0 && (
                                                <div className='img-recommended-products'>
                                                    <img src={line} alt="" />
                                                </div>
                                            )}
                                        </React.Fragment>
                                    ))}
                                    <div className='layout-details-recommended'>
                                        <p className='text-amber-500 text-[1.5vw] font-bold'>{advice.label}</p>
                                        <p>{advice.description}</p>
                                        {advice.salePrice ? (
                                            <p className='text-amber-500 text-[1.2vw] font-bold'>{advice.salePrice}.-</p>
                                        ) : (
                                            <p className='text-amber-500 text-[1.2vw] font-bold'>{advice.price}.-</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='layout-all-products'>
                    <p className='text-sction-all text-[1.1vw] font-bold tracking-[.09em] cursor-default'>สินค้าทั้งหมด</p>
                    <div className='grid-list-all'>
                        {scriptAll.map((all, index) => (
                            <div key={index} onClick={() => onProduct(all)} className='card-all-products hover:scale-[1.02]'>
                                {all.img.map((line, index) => (
                                    <React.Fragment key={index}>
                                        {index === 0 && (
                                            <div className='img-all-products'>
                                                <img src={line} alt="" />
                                            </div>
                                        )}
                                    </React.Fragment>
                                ))}
                                <div className='layout-details-all'>
                                    <p className='text-amber-500 text-[1.5vw] font-bold'>{all.label}</p>
                                    <p>{all.description}</p>
                                    {all.salePrice ? (
                                        <p className='text-amber-500 text-[1.2vw] font-bold'>{all.salePrice}.-</p>
                                    ) : (
                                        <p className='text-amber-500 text-[1.2vw] font-bold'>{all.price}.-</p>
                                    )}

                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Products