// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { getFirestore } from "firebase/firestore"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDVCOvpnIVnf-Wxb5opwNUJhJNmbA86jO4",
  authDomain: "funny-production-2fbe8.firebaseapp.com",
  projectId: "funny-production-2fbe8",
  storageBucket: "funny-production-2fbe8.appspot.com",
  messagingSenderId: "548479374938",
  appId: "1:548479374938:web:4f3ea62454e14b69db6a63",
  measurementId: "G-79H99SH40V"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const db = getFirestore(app)

export { db }